/**
* 参数处理
* @param {*} params  参数
*/
export function tansParams(params) {
    let result = ''
    for (const propName of Object.keys(params)) {
        const value = params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof (value) !== "undefined") {
            if (typeof value === 'object') {
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && typeof (value[key]) !== 'undefined') {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        result += subPart + encodeURIComponent(value[key]) + "&";
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + "&";
            }
        }
    }
    return result
}


/**
 * 时间格式化
 * @param {date|string|number} time
 * @param {string} formatType
 * @returns {string }
 */
export const formatTime = (time, formatType) => {
    if (arguments.length === 0) {
        return null
    }
    const format = formatType || 'Y-M-D hh:mm:ss w'
    const types = ['undefined', null, 'null', '']
    const timeType = typeof time
    let date
    if (timeType === 'undefined' || types.includes(time)) {
        date = new Date()
    } else if (Object.prototype.toString.call(time) === '[object Date]') {
        date = time
    } else if (Object.prototype.toString.call(time) !== '[object Date]') {
        // 'time必须是date|string|number类型'
        return '数据类型错误'
    } else {
        if ((timeType === 'string') && (/^[0-9]+$/.test(time))) {
            time = +time
        }
        if ((timeType === 'number') && (time.toString().length === 10)) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        Y: date.getFullYear(),
        M: date.getMonth() + 1,
        D: date.getDate(),
        hh: date.getHours(),
        mm: date.getMinutes(),
        ss: date.getSeconds(),
        w: date.getDay()
    }
    const time_str = format.replace(/(Y|M|D|hh|mm|ss|w)+/g, (result, key) => {
        let value = formatObj[key]
        if (key === 'w') {
            const weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
            return weeks[value]
        }
        return key === 'Y' ? value : `0${value}`.slice(-2)
    })
    return time_str
}
