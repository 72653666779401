import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

const routes = [
    {
        path: '/',
        component: Layout,
        redirect: 'home',
        children: [
            {
                path: 'home',
                component: () => import('@/views/index'),
                name: 'home',
                meta: { title: '首页', affix: true }
            }
        ]
    },
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: 'news',
                component: () => import('@/views/news'),
                name: 'news',
                meta: { title: '新闻动态', affix: true }
            }
        ]
    }, {
        path: '/',
        component: Layout,
        children: [
            {
                path: 'newDetails',
                component: () => import('@/views/news/details'),
                name: 'newDetails',
                meta: { title: '新闻详情', affix: true }
            }
        ]
    },
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: 'scheme',
                component: () => import('@/views/scheme'),
                name: 'scheme',
                meta: { title: '解决方案', affix: true }
            }
        ]
    },
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: 'schemeList',
                component: () => import('@/views/scheme/schemeList.vue'),
                name: 'schemeList',
                meta: { title: '方案列表', affix: true }
            }
        ]
    },
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: 'schemeDetails',
                component: () => import('@/views/scheme/schemeDetails.vue'),
                name: 'schemeDetails',
                meta: { title: '方案详情', affix: true }
            }
        ]
    },
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: 'case',
                component: () => import('@/views/case'),
                name: 'case',
                meta: { title: '经典案列', affix: true }
            }
        ]
    },
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: 'caseDetails',
                component: () => import('@/views/case/details'),
                name: 'caseDetails',
                meta: { title: '案例详情', affix: true }
            }
        ]
    },
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: 'about',
                component: () => import('@/views/about'),
                name: 'about',
                meta: { title: '关于我们', affix: true }
            }
        ]
    },
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: 'recruitment',
                component: () => import('@/views/recruitment'),
                name: 'recruitment',
                meta: { title: '招贤纳士', affix: true }
            }
        ]
    }
]


export default new Router({
    mode: 'history', // 去掉url中的#
    routes,
})