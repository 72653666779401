<template>
  <div class="footer">
    <img src="../../../assets/footer/footerbgc.png" class="footerImg" />
    <div class="company-info-box">
      <div class="title">钉钉重庆交付中心</div>
      <div class="info-form-box">
        <div class="left-box">
          <div class="company-name">重庆龙亨科技有限公司</div>
          <div class="navbar">
            <router-link
              v-for="item in navbarData"
              :key="item.name"
              :to="item.path"
              class="router-link-active"
              >{{ item.name }}</router-link
            >
          </div>
          <div class="address text">地址：{{ addressData.address }}</div>
          <div class="phone text">电话：{{ addressData.iphone }}</div>
          <div class="fax text">邮箱：{{ addressData.mailbox }}</div>
        </div>
        <div class="right-box">
          <el-form :model="form.formData" :rules="form.rules" ref="ruleForm">
            <el-form-item prop="name">
              <el-input
                v-model="form.formData.name"
                placeholder="姓名"
              ></el-input>
            </el-form-item>
            <el-form-item prop="iphone">
              <el-input
                v-model="form.formData.iphone"
                placeholder="电话"
              ></el-input>
            </el-form-item>
            <el-form-item prop="mailbox">
              <el-input
                v-model="form.formData.mailbox"
                placeholder="邮箱"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <div class="consult" @click="consult">预约咨询</div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="bottom-box">
      版权所有©重庆龙亨科技有限公司 | 备案号：渝ICP备20005215号-1
    </div>
  </div>
</template>

<script>
import { getAddress, consult } from "@/api/footer.js";
export default {
  name: "Footer",
  data() {
    return {
      form: {
        formData: {
          name: "",
          iphone: "",
          mailbox: "",
        },
        rules: {
          name: [
            { required: true, message: "请输入您的姓名", trigger: "blur" },
          ],
          iphone: [
            { required: true, message: "请输入您的电话", trigger: "blur" },
          ],
          mailbox: [
            { required: true, message: "请输入您的邮箱", trigger: "blur" },
          ],
        },
      },
      navbarData: [
        { name: "首页", path: "/home" },
        {
          name: "解决方案",
          path: "/scheme",
        },
        {
          name: "经典案列",
          path: "/case",
        },
        {
          name: "关于我们",
          path: "/about",
        },
        {
          name: "招贤纳士",
          path: "/recruitment",
        },
      ],
      addressData: {},
    };
  },
  methods: {
    async getAddress() {
      const { data } = await getAddress();
      this.addressData = data[0];
    },
    // 预约咨询
    consult() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          consult(this.form.formData).then((res) => {
            if (res.message == "请求成功") {
              alert("预约成功");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  created() {
    this.getAddress();
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 885px) {
  .footer {
    position: relative;
    .footerImg {
      width: 100%;
      height: 600px;
      display: block;
    }
    .company-info-box {
      position: absolute;
      top: 82px;
      left: 50%;
      margin-left: -642.5px;
      width: 1285px;
      .title {
        font-size: 40px;
        font-weight: 400;
        text-align: CENTER;
        color: #ffffff;
        cursor: default;
      }
      .info-form-box {
        display: grid;
        grid-template-columns: 50% 50%;
        margin-top: 87px;
        .left-box {
          cursor: default;
          .company-name {
            font-size: 20px;
            font-weight: 400;
            color: #ffffff;
          }
          .navbar {
            margin-top: 30px;
            .router-link-active {
              width: 100px;
              text-align: center;
              text-decoration: none;
              color: #ffffff;
              font-size: 14px;
              font-weight: 400;
              &:not(:last-child)::after {
                content: "";
                display: inline-block;
                height: 10px;
                border-right: 2px solid #fff;
                margin: 0 10px;
              }
            }
          }
          .text {
            margin-top: 35px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .right-box {
          .el-input__inner {
            /* 使input框的背景变透明 */
            background-color: transparent;
            border: 1px solid #5172af;
            color: #fff;
          }
          &::placeholder {
            color: #fff;
          }
          .consult {
            width: 140px;
            height: 48px;
            background: #2db5ff;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            line-height: 48px;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
    }
    .bottom-box {
      height: 80px;
      background-color: #152a74;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 80px;
      color: #dce7fc;
      cursor: default;
    }
  }
}
@media screen and (max-width: 885px) {
  .footer {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    box-sizing: content-box;
    background-color: #152a74;
    .footerImg {
      display: none;
    }
    .company-info-box {
      width: 100%;
      height: 125px;
      background-color: #152a74;
      .title {
        display: none;
      }
      .info-form-box {
        .left-box {
          box-sizing: border-box;
          padding-top: 5px;
          cursor: default;
          .company-name {
            text-align: center;
            font-size: 20px;
            font-weight: 400;
            color: #dce7fc;
            line-height: 40px;
          }
          .navbar {
            display: none;
            .router-link-active {
              width: 100px;
              text-align: center;
              text-decoration: none;
              color: #ffffff;
              font-size: 14px;
              font-weight: 400;
              &:not(:last-child)::after {
                content: "";
                display: inline-block;
                height: 10px;
                border-right: 2px solid #fff;
                margin: 0 10px;
              }
            }
          }
          .text {
            text-align: center;
            margin-top: 6px;
            font-size: 14px;
            font-weight: 400;
            color: #dce7fc;
          }
        }
        .right-box {
          display: none;
          .el-input__inner {
            /* 使input框的背景变透明 */
            background-color: transparent;
            border: 1px solid #5172af;
            color: #fff;
          }
          &::placeholder {
            color: #fff;
          }
          .consult {
            width: 140px;
            height: 48px;
            background: #2db5ff;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            line-height: 48px;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
    }
    .bottom-box {
      height: 30px;
      background-color: #152a74;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 30px;
      color: #dce7fc;
      cursor: default;
    }
  }
}
</style>