<template>
  <div>
    <div
      :style="{ backgroundColor: activeBackgroundColor }"
      class="header"
      ref="header"
    >
      <div class="navbar">
        <div
          class="navbar-control"
          ref="navbar"
          @click="unflodBtn"
          v-if="!relationContentFlag"
        >
          <span class="control-icon"></span>
          <span class="control-icon"></span>
          <span class="control-icon"></span>
        </div>
        <div v-else style="width: 54px"></div>
        <img
          src="../../../assets/header/logo.png"
          @click="goHome"
          v-if="elationImageFlag"
        />
        <img src="../../../assets/header/logo2.jpg" @click="goHome" v-else />
        <div
          class="relation"
          v-if="!navbarContentFlag"
          @click="relationUnflodBtn"
        >
          <img
            src="../../../assets/header/联系我们.png"
            v-if="elationImageFlag"
          />
          <img src="../../../assets/header/联系我们 (1).png" v-else />
        </div>
        <div v-else style="width: 54px"></div>
        <div class="navbar-box">
          <router-link
            v-for="item in navbarData"
            :key="item.name"
            :to="item.path"
            @click.native="skipPathBtn(item)"
            class="router-link-active"
            >{{ item.name }}</router-link
          >
        </div>
      </div>
      <div class="navbar-content" v-if="navbarContentFlag">
        <router-link
          v-for="item in navbarData"
          :key="item.name"
          :to="item.path"
          @click.native="skipPathBtn(item)"
          class="router-link-active"
          >{{ item.name }}</router-link
        >
      </div>
      <div class="relation-content" v-if="relationContentFlag">
        <div class="relation-title">钉钉重庆交付中心</div>
        <div class="relation-box">
          <el-form :model="form.formData" :rules="form.rules" ref="ruleForm">
            <el-form-item prop="name">
              <el-input
                v-model="form.formData.name"
                suffix-icon="el-icon-user"
                placeholder="姓名"
              ></el-input>
            </el-form-item>
            <el-form-item prop="iphone">
              <el-input
                v-model="form.formData.iphone"
                suffix-icon="el-icon-phone-outline"
                placeholder="电话"
              ></el-input>
            </el-form-item>
            <el-form-item prop="mailbox">
              <el-input
                v-model="form.formData.mailbox"
                suffix-icon="el-icon-message"
                placeholder="邮箱"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <div class="consult" @click="consult">预约咨询</div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <el-carousel :height="screenwidth" v-if="pathName == '首页'">
      <el-carousel-item v-for="item in bannerList" :key="item.id">
        <el-image :src="item.imgUrl" style="width: 100%"></el-image>
      </el-carousel-item>
    </el-carousel>
    <img
      :src="imgSrc"
      style="width: 100%; height: 100%; display: block"
      class="bannerImg"
      v-else
    />
  </div>
</template>

<script>
import { getBannerList } from "@/api/home";
import { consult } from "@/api/footer.js";
import { Toast } from "vant";
export default {
  name: "Header",
  data() {
    return {
      form: {
        formData: {
          name: "",
          iphone: "",
          mailbox: "",
        },
        rules: {
          name: [
            { required: true, message: "请输入您的姓名", trigger: "blur" },
          ],
          iphone: [
            { required: true, message: "请输入您的电话", trigger: "blur" },
          ],
          mailbox: [
            { required: true, message: "请输入您的邮箱", trigger: "blur" },
          ],
        },
      },
      bannerList: [],
      screenwidth: (document.documentElement.clientWidth * 690) / 1920 + "px",
      imgSrc: "",
      pathName: "首页",
      activeBackgroundColor: "",
      navbarData: [
        { name: "首页", path: "/home" },
        {
          name: "解决方案",
          path: "/scheme",
          imgSrc: require("@/assets/scheme/index.png"),
        },
        {
          name: "经典案列",
          path: "/case",
          imgSrc: require("@/assets/case/index.png"),
        },
        {
          name: "关于我们",
          path: "/about",
          imgSrc: require("@/assets/about/index.png"),
        },
        {
          name: "招贤纳士",
          path: "/recruitment",
          imgSrc: require("@/assets/recruitment/index.png"),
        },
      ],
      // 菜单栏显隐
      navbarContentFlag: false,
      // 联系我们显隐
      relationContentFlag: false,
      // logo显隐
      elationImageFlag: true,
    };
  },
  methods: {
    // 预约咨询按钮
    consult() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          consult(this.form.formData).then((res) => {
            if (res.message == "请求成功") {
              this.relationContentFlag = false;
              this.elationImageFlag = true;
              this.activeBackgroundColor = "#152a74";
              Toast("预约成功");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 预约咨询
    relationUnflodBtn() {
      this.relationContentFlag = !this.relationContentFlag;
      this.elationImageFlag = !this.elationImageFlag;
      if (this.relationContentFlag) {
        this.activeBackgroundColor = "#fff";
      } else {
        this.activeBackgroundColor = "#152a74";
      }
    },
    // 移动端打开导航栏按钮并添加动画
    unflodBtn() {
      this.navbarContentFlag = !this.navbarContentFlag;
      this.elationImageFlag = !this.elationImageFlag;
      const navbar = this.$refs.navbar.childNodes;
      if (this.navbarContentFlag) {
        this.activeBackgroundColor = "#fff";
        navbar.forEach((item) => {
          item.className = "control-icon navbar-animation";
        });
      } else {
        this.activeBackgroundColor = "#152a74";
        navbar.forEach((item) => {
          item.className = "control-icon";
        });
      }
    },
    // 回到主页
    goHome() {
      this.$router.push({
        path: "/home",
      });
    },
    // 获取首页banner图片
    async getBannerList() {
      const {
        data: { content },
      } = await getBannerList();
      this.bannerList = content;
    },
    // 导航栏跳转
    skipPathBtn(val) {
      this.imgSrc = val.imgSrc;
      this.pathName = val.name;
      this.navbarContentFlag = false;
      this.elationImageFlag = true;
      this.relationContentFlag = false;
      const navbar = this.$refs.navbar.childNodes;
      this.activeBackgroundColor = "#152a74";
      navbar.forEach((item) => {
        item.className = "control-icon";
      });
    },
    // 监听页面滚动提奥高度改变导航栏背景色
    handleScroll() {
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > 180) {
        this.activeBackgroundColor = "#2c4fa5";
      } else {
        this.activeBackgroundColor = "";
      }
    },
  },
  created() {
    this.getBannerList();
  },
  mounted() {
    const navbar = this.$refs.navbar.childNodes;
    window.onresize = () => {
      return (() => {
        if (document.documentElement.clientWidth > 1519) {
          this.screenwidth =
            (document.documentElement.clientWidth * 685) / 1920 + "px";
        } else {
          this.screenwidth =
            (document.documentElement.clientWidth * 685) / 1920 + "px";
        }
        if (document.documentElement.clientWidth > 885) {
          this.activeBackgroundColor = "";
          this.elationImageFlag = true;
        } else {
          this.activeBackgroundColor = "#152a74";
          this.elationImageFlag = true;
          this.relationContentFlag = false;
          this.navbarContentFlag = false;
          navbar.forEach((item) => {
            item.className = "control-icon";
          });
        }
      })();
    };
    window.addEventListener("scroll", this.handleScroll, true);
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 885px) {
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 10;
    .navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      width: 1285px;
      height: 80px;
      .navbar-control {
        display: none;
      }
      div {
        display: none;
      }
      img {
        height: 50px;
        width: 155px;
        cursor: pointer;
      }
      .relation {
        display: none;
      }
      /* <768px */
      .navbar-box {
        display: flex;
        .router-link-active {
          width: 100px;
          text-align: center;
          text-decoration: none;
          color: #fff;
        }
      }
    }
    .navbar-content {
      display: none;
    }
    .relation-content {
      display: none;
    }
  }
}
@media screen and (max-width: 885px) {
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    background-color: #152a74;
    z-index: 10;
    .navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      width: 100%;
      height: 50px;
      .navbar-control {
        padding: 13px 16px;
        -webkit-tap-highlight-color: transparent;
        .control-icon {
          display: block;
          padding-top: 3px;
          padding-bottom: 3px;
          transition: all 0.4s ease;
        }
        .control-icon::before {
          content: "";
          display: block;
          height: 2px;
          width: 22px;
          background-color: #fff;
          border-top-right-radius: 2px;
          border-bottom-left-radius: 2px;
          transition: transform 0.4s ease 0.2s, background-color 0.5s ease 0s;
        }
        .navbar-animation:before {
          background-color: #152a74;
        }
        .navbar-animation-white:before {
          background-color: #fff;
        }
        .navbar-animation:nth-child(1) {
          transform: translateY(8px);
        }
        .navbar-animation:nth-child(1):before {
          transform: rotate(45deg);
        }
        .navbar-animation:nth-child(2) {
          opacity: 0;
        }
        .navbar-animation:nth-child(3) {
          transform: translateY(-8px);
        }
        .navbar-animation:nth-child(3):before {
          transform: rotate(-45deg);
        }
      }

      img {
        height: 30px;
        width: 90px;
        cursor: pointer;
      }
      .relation {
        padding: 11px 16px;
        box-sizing: border-box;
        width: 54px;
        height: 50px;
        img {
          height: 28px;
          width: 24px;
        }
      }
      /* <768px */
      .navbar-box {
        display: none;
      }
    }
    .navbar-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: calc(100vh - 50px);
      background-color: #fff;
      overflow-x: hidden;
      overflow-y: scroll;
      z-index: 10;
      position: relative;
      .router-link-active {
        width: 100px;
        height: 55px;
        line-height: 55px;
        text-align: center;
        text-decoration: none;
        color: #303233;
      }
    }
    .relation-content {
      transition: all 0.6s ease;
      height: calc(100vh - 50px);
      background-color: #fff;
      overflow-x: hidden;
      overflow-y: scroll;
      z-index: 10;
      position: relative;
      .relation-title {
        margin-top: 30px;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
      }
      .relation-box {
        width: 80%;
        margin: 30px auto;
        .consult {
          width: 100%;
          height: 40px;
          background-color: #152a74;
          color: #fff;
          text-align: center;
          line-height: 40px;
        }
      }
    }
  }
  ::v-deep .el-carousel{
    margin-top: 50px;
  }
  .bannerImg{
    margin-top: 50px;

  }
}
</style>