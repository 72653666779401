<template>
  <div class="breadcrumb">
    <div class="breadcrumb-main">
      <span style="cursor: default">您的位置：</span>
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
      >
        <el-breadcrumb-item v-for="item in pathLists" :key="item.path">
          <router-link :to="item.path">{{ item.meta.title }}</router-link>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
  data() {
    return {
      pathLists: [],
    };
  },
  methods: {
    getBreadcrumb() {
      // only show routes with meta.title
      let matched = this.$route.matched.filter(
        (item) => item.meta && item.meta.title
      );
      const first = matched[0];

      if (!this.isDashboard(first)) {
        matched = [{ path: "/home", meta: { title: "首页" } }].concat(matched);
      }

      this.pathLists = matched.filter(
        (item) => item.meta && item.meta.title && item.meta.breadcrumb !== false
      );
    },
    isDashboard(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return name.trim() === "home";
    },
    // handleLink(item) {
    //   const { redirect, path } = item;
    //   if (redirect) {
    //     this.$router.push(redirect);
    //     return;
    //   }
    //   this.$router.push(path);
    // },
  },
  created() {
    this.getBreadcrumb();
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 885px) {
  .breadcrumb {
    height: 76px;
    border-bottom: 1px solid #e5e5e5;
    .breadcrumb-main {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 76px;
      width: 1000px;
      margin: 0 auto;
    }
  }
}
@media screen and (max-width: 885px) {
  .breadcrumb{
    display: none;
  }
}
</style>