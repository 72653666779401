<template>
  <div>
    <Header />
    <Breadcrumb v-if="breadcrumbShow" />
    <Main />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header";
import Breadcrumb from "./components/Breadcrumb";
import Main from "./components/Main.vue";
import Footer from "./components/Footer";
export default {
  name: "Layout",
  components: {
    Header,
    Breadcrumb,
    Main,
    Footer,
  },
  watch: {
    $route(to) {
      if (to.name == "home") {
        this.breadcrumbShow = false;
      } else {
        this.breadcrumbShow = true;
      }
    },
  },

  data() {
    return {
      breadcrumbShow: true,
    };
  },
  created() {
    if (this.$route.name == "home") {
      this.breadcrumbShow = false;
    }
  },
};
</script>

<style>
</style>