import Vue from 'vue'
import App from './App.vue'
import router from "./router"

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import "@/assets/styles/index.less"

import 'hover.css'

import vant from "vant"

import 'vant/lib/index.css';



Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(vant);


new Vue({
  router,
  render: h => h(App),
  // beforeCreate() {
  //   Vue.prototype.$bus = this
  // }
}).$mount('#app')
