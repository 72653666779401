import request from "@/utils/request"

// 获取地址 
export function getAddress() {
    return request({
        url: "contantUs/selectContactUs",
        method: "GET",
    })
}

// 预约咨询
export function consult(data) {
    return request({
        url: "appointment/add",
        method: "post",
        data
    })
}