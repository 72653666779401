import request from "@/utils/request"

// 获取首页banner 
export function getBannerList() {
    return request({
        url: "banner/list",
        method: "GET",
    })
}

// 获取首页新闻资讯
export function searchNews(params) {
    return request({
        url: `web/selectJournalismOne`,
        method: "GET",
        params
    })
}